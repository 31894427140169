import React from "react"
import styled from "styled-components"
import { CheckerBackground } from "@components/atoms"

const imgixPath = (input: string) =>
  input.replace(
    `cdn.sanity.io/files/${process.env.GATSBY_SANITY_PROJECT_ID}/production`,
    "el-files.imgix.net"
  )

const Video = ({
  autoPlay,
  muted,
  loop,
  hideIfMobile,
  hideIfDesktop,
  playsInline,
  src,
  visible,
}: VideoProps): React.ReactElement => {
  if (!src) return <></>

  const ref = React.useRef<HTMLVideoElement>(null)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (!ref.current) return
    ref.current.onload = () => setLoaded(true)
  }, [ref])

  return (
    <Wrapper>
      <CheckerBackground {...{ visible }} />
      <VideoTag
        {...{
          autoPlay,
          muted,
          loop,
          hideIfMobile,
          hideIfDesktop,
          playsInline,
          ref,
          visible,
        }}
        className={`${loaded && "loaded"}`}
      >
        <source src={imgixPath(src)} />
      </VideoTag>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const VideoTag = styled.video<{
  rotation?: number
  hideIfDesktop?: boolean
  hideIfMobile?: boolean
  visible: boolean
}>`
  display: block;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 767px) {
    ${props => props.hideIfMobile && `display: none;`}
  }
  @media only screen and (min-width: 768px) {
    ${props => props.hideIfDesktop && `display: none;`}
  }
  transition: opacity 0.5s ease-in-out;
  @media only screen and (min-width: 768px) {
    transition: opacity 0.5s ease-in-out, transform 0.25s ease-out;
  }
  @media only screen and (max-width: 767px) {
    transform: none !important;
  }
  background: url("/images/loading_checks.svg");
  background-size: auto 20px;
  &.loaded {
    background: none;
  }
  transition: opacity 0.35s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
`

type VideoProps = {
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  hideIfMobile?: boolean
  hideIfDesktop?: boolean
  playsInline?: boolean
  src: string
  visible: boolean
}

export default Video
