import React from "react"
import styled from 'styled-components'
import { BlockRenderer, LinkMask } from '@components/atoms'
import { StickyNote } from '@components/molecules'
import { GridItemCaptionProps } from '@types'
import { GridItemWrapper, ChildrenWrapper } from './GridItem'


const GridItemCaption = ({
  link,
  post,
  itemFX,
  layout: {
    fullBleed,
    layout,
  },
  children,
  gridItemTypeSwitch,
  borderType,
  stickyNote,
  zIndex,
  pageBackgroundColor,
  className,
}: GridItemCaptionProps & { className?: string }): React.ReactElement => {
  if( !gridItemTypeSwitch.gridItemCaption ) return <></>  
  const {
    gridItemCaption: {
      _rawCaption,
      anchor,
    },
  } = gridItemTypeSwitch

  const { adjustMargin, layer } = itemFX?.effects || { adjustMargin: undefined, layer: undefined }

  return (
    <StyledGridItemWrapper {...{ adjustMargin, layer, fullBleed, borderType, className, zIndex, pageBackgroundColor }} {...layout} {...itemFX} visible={true} >
      {stickyNote?.hasStickyNote && stickyNote?.stickyNote && (<StickyNote {...stickyNote.stickyNote} />)}
      <LinkMask {...{link, post}} />
      <ItemCaption id={anchor?.toLowerCase()}>
        <BlockRenderer>{_rawCaption}</BlockRenderer> 
      </ItemCaption>
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </StyledGridItemWrapper>
  )
}


const StyledGridItemWrapper = styled(props => <GridItemWrapper {...props} />)`
  padding: 0px 0px;
  @media only screen and (min-width: 768px) {
    padding: 4.16vw 0;    
  }
`

const ItemCaption = styled.div`
  position: relative;
  text-align: center;
`


export default GridItemCaption