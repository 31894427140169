import React from "react"
import styled from "styled-components"
import { Link } from "@components/atoms"
import { palette } from "@theme"
import { useLocation } from "@reach/router"

interface MobileNavigation {
  navVisible: boolean
}

const MobileNavigation = ({ navVisible }: MobileNavigation) => {
  const { pathname } = useLocation()
  return (
    <Wrapper {...{ navVisible }}>
      <Headline>
        <h1>
          <MobileHeader>
            <Link to="/">Studio Elana Schlenker</Link>
          </MobileHeader>
        </h1>
      </Headline>

      <Nav {...{ navVisible }}>
        <li>
          <Link className="home" to="/">
            Work
          </Link>
        </li>
        <li>
          <Link className="about" to="/about">
            About
          </Link>
        </li>
        <li>
          <Link
            className={`tags ${
              pathname.substr(0, 5) === "/tags" ? "current" : ""
            }`}
            to="/tags"
          >
            Tags
          </Link>
        </li>
        <li>
          <a className="email" href="mailto:hello@elanaschlenker.com">
            Email
          </a>
        </li>
      </Nav>
    </Wrapper>
  )
}

const Wrapper = styled.div<MobileNavigation>`
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: rgb(255, 243, 217);

  opacity: ${props => (props.navVisible ? 1 : 0)};
  pointer-events: ${props => (props.navVisible ? "all" : "none")};
  transition: opacity 0.3s ease-in-out;
  will-change: opacity;
`

const Nav = styled.ul<MobileNavigation>`
  @keyframes rainbow {
    0% {
      background-position: 200% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  padding: 0;
  margin: 0;
  line-height: 1em;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 16.5vw;
  list-style-type: none;
  width: 100%;
  li {
    width: 100%;
    border-top: 2px solid black;
    a {
      display: block;
      width: 100%;
      padding: 0.25em 0;
      &.about {
        background-color: ${palette.mauve};
      }
      &.tags {
        background-color: ${palette.brightSun};
      }
      &.email {
        animation: rainbow 3s linear infinite;
        background: linear-gradient(
          90deg,
          rgb(56, 185, 255),
          rgb(33, 185, 131),
          rgb(255, 255, 55),
          rgb(255, 160, 98),
          rgb(219, 157, 249),
          rgb(56, 185, 255),
          rgb(33, 185, 131),
          rgb(255, 255, 55),
          rgb(255, 160, 98),
          rgb(219, 157, 249),
          rgb(56, 185, 255)
        );
        background-size: 200% 100%;
        padding-bottom: 3em;
      }
    }
    a[aria-current="page"],
    a.current {
      border: 2px solid black;
      border-radius: 100% 100% 100% 100% / 100% 100% 100% 100%;
    }
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }

  opacity: ${props => (props.navVisible ? 1 : 0)};
  transform: translateY(${props => (props.navVisible ? 0 : 50)}vh);
  pointer-events: ${props => (props.navVisible ? "all" : "none")};
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
  will-change: transform, opacity;
`

const Headline = styled.header`
  display: block;
  width: 100%;

  @keyframes wobble {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(5deg);
    }
    75% {
      transform: rotateZ(-5deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }

  h1 {
    font-family: "NHaasGroteskDSW01-65Md", sans-serif;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 9.5vw;
    margin: -0.03vw 0 0 0;
    letter-spacing: -0.02em;
    &:hover span {
      animation: wobble 0.75s linear infinite;
    }
    &:hover span:nth-of-type(2) {
      animation-direction: reverse;
    }
    span {
      margin-left: -0.05em;
      display: block;
      line-height: 0.875em;
      letter-spacing: -0.05em;
      transform-origin: top center;
    }
  }
`

const MobileHeader = styled.header`
  line-height: 0.85em;
  font-size: 16.5vw;
  padding: 10px 0;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

export default MobileNavigation
