
import React from "react"
import styled from 'styled-components'
import { Link } from '@components/atoms'
import { palette } from '@theme'
{/* import { useHistoryDispatch } from '@utils' */}



const LinkMask = ({post, link}: LinkMaskProps) => {
  {/*
    const historyDispatch = useHistoryDispatch()
    historyDispatch({type: 'addUrl', payload: { url: `/${post.slug.current}` }})
    */}

  if(link) {
    return <AnchorWrapper href={link} />
  }
  if(post) {
    return <LinkWrapper to={`/${post.slug.current}`} />
  }
  return <></>
}


const LinkMaskStyle = `
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  & ~ div .linkHighlight {
    transition: color 0.25s ease-in-out;    
  }
  &:hover ~ div .linkHighlight {
    color: ${palette.lime};
  }
`

const AnchorWrapper = styled.a`
  ${LinkMaskStyle}
`

const LinkWrapper = styled(props => <Link {...props} />)`
  ${LinkMaskStyle}
`


interface LinkMaskProps {
  post?: any
  link?: string
}


export default LinkMask