import styled from "styled-components"

const CheckerBackground = styled.div<{ visible: boolean }>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/images/about_checks.svg");
  background-size: auto 12vw;
  @media only screen and (min-width: 768px) {
    background-size: auto 4vw;
  }
  transition: opacity 0.35s 0.35s ease-in-out;
  opacity: ${props => (props.visible ? 0 : 1)};
`

export default CheckerBackground
