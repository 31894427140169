import React from "react"
import styled from "styled-components"

interface MobileNavButtonProps {
  navVisible: boolean
}

const MobileNavButton = ({
  navVisible,
  ...props
}: MobileNavButtonProps): React.ReactElement => {
  return (
    <Wrapper {...{ navVisible }} {...props}>
      <Line {...{ navVisible }} />
      <Line {...{ navVisible }} />
      <Line {...{ navVisible }} />
    </Wrapper>
  )
}

const Line = styled.div<Pick<MobileNavButtonProps, "navVisible">>`
  position: absolute;
  left: 10px;
  top: 20px;
  width: 20px;
  height: 2px;
  background: black;
  transform: rotate3d(0, 0, 1, 0deg);
  transition: transform 0.35s ease-in-out;

  &:nth-of-type(1) {
    top: 20px;
    transform-origin: top left;
    transform: ${props =>
      props.navVisible ? `rotateZ(63deg) scale(2.3, 1)` : `rotateZ(0deg)`};
  }

  &:nth-of-type(2) {
    top: 40px;
    transform-origin: center center;
    transform: ${props => (props.navVisible ? `scale(0)` : `scale(1)`)};
  }

  &:nth-of-type(3) {
    top: 60px;
    transform-origin: bottom left;
    transform: ${props =>
      props.navVisible ? `rotateZ(-63deg) scale(2.3, 1)` : `rotateZ(0deg)`};
  }
`

const Wrapper = styled.div<Pick<MobileNavButtonProps, "navVisible">>`
  @media only screen and (min-width: 768px) {
    display: none;
  }
  position: fixed;
  z-index: 100;
  top: 12.5px;
  right: 15px;
  width: 40px;
  height: 80px;
  background: ${props =>
    props.navVisible
      ? "rgba(155, 141, 131)"
      : `linear-gradient(
    0deg,
    rgb(56, 185, 255),
    rgb(33, 185, 131),
    rgb(255, 255, 55),
    rgb(255, 160, 98),
    rgb(219, 157, 249),
    rgb(56, 185, 255),
    rgb(33, 185, 131),
    rgb(255, 255, 55),
    rgb(255, 160, 98),
    rgb(219, 157, 249),
    rgb(56, 185, 255)
  )`};

  background-size: 100% 400%;
  animation: buttonRainbow 120s linear infinite;

  border: 2px solid black;
  border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
`

export default MobileNavButton
