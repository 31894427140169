import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"

const Link = ({
  to,
  children,
  className,
  ...props
}: {
  to: string
  className?: string
  children: React.ReactNode
  onClick?: any
}): React.ReactElement => {
  return (
    <TransitionLink
      {...{ to, className }}
      {...props}
      exit={{ length: 0.52 }}
      entry={{ delay: 0.52 }}
    >
      {children}
    </TransitionLink>
  )
}

export default Link
