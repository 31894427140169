import React from 'react'

type Action = {
  type: 'addUrl'
  payload?: {
    url: string
  }
}

type Dispatch = (action: Action) => void
interface State {
  visitedUrls: Array<string>
}
type HistoryProviderProps = {
  children: React.ReactNode
}

const initialState: State = {
  visitedUrls: (typeof window !== 'undefined') ? window?.localStorage?.getItem('visitedUrls')?.split(',') ?? [] : [],
}

const HistoryContext = React.createContext<State | undefined>(initialState)
const HistoryDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function historyReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'addUrl': {
      const newVisitedUrls = [...state.visitedUrls, action.payload?.url ?? ''].filter((v, i, a) => a.indexOf(v) === i)
      window?.localStorage?.setItem('visitedUrls', newVisitedUrls.join(','))
      return { visitedUrls: newVisitedUrls }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function HistoryProvider({children}: HistoryProviderProps) {
  const [state, dispatch] = React.useReducer(historyReducer, initialState)
  return (
    <HistoryContext.Provider value={state}>
      <HistoryDispatchContext.Provider value={dispatch}>
        {children}
      </HistoryDispatchContext.Provider>
    </HistoryContext.Provider>
  )
}

function useHistory() {
  const context = React.useContext(HistoryContext)
  if (context === undefined) {
    throw new Error('useHistory must be used within a HistoryProvider')
  }
  return context
}

function useHistoryDispatch() {
  const context = React.useContext(HistoryDispatchContext)
  if (context === undefined) {
    throw new Error('useHistoryDispatch must be used within a HistoryProvider')
  }
  return context
}

export { HistoryProvider, useHistory, useHistoryDispatch }