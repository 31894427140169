import React from "react"
import { Picture, LinkMask, CheckerBackground } from "@components/atoms"
import { StickyNote } from "@components/molecules"
import { GridItemWrapper, ChildrenWrapper } from "./GridItem"
import { GridItemImageProps } from "@types"
import { useInViewport } from "react-in-viewport"

const GridItemImage = ({
  link,
  post,
  borderType,
  itemFX,
  layout: { fullBleed, layout },
  children,
  description,
  gridItemTypeSwitch,
  stickyNote,
  zIndex,
  pageBackgroundColor,
  className,
}: { className?: string } & GridItemImageProps): React.ReactElement => {
  if (!gridItemTypeSwitch.gridItemImage) return <></>
  const {
    gridItemImage: { image, mobileImage },
  } = gridItemTypeSwitch

  const ref = React.useRef<HTMLDivElement>(null)
  const { inViewport } = useInViewport(ref, { threshold: 0.15 })

  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if (!visible && inViewport) setVisible(true)
  }, [inViewport])

  const { adjustMargin, layer } = itemFX?.effects || {
    adjustMargin: undefined,
    layer: undefined,
  }

  return (
    <GridItemWrapper
      {...{
        adjustMargin,
        layer,
        fullBleed,
        className,
        borderType,
        ref,
        visible,
        zIndex,
        pageBackgroundColor,
      }}
      {...layout}
      {...itemFX}
    >
      {stickyNote?.hasStickyNote && stickyNote?.stickyNote && (
        <StickyNote {...stickyNote.stickyNote} />
      )}
      <LinkMask {...{ link, post }} />
      <Picture
        {...{ visible }}
        src={image?.asset.url}
        dimensions={image?.asset.metadata.dimensions}
        mobileSrc={mobileImage?.asset.url}
        alt={description}
        columns={layout?.columns || 12}
        lines={fullBleed}
        blackBackground={borderType === "outline" || borderType === "web"}
      />
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </GridItemWrapper>
  )
}

export default GridItemImage
