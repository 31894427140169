import React from "react"
import styled from "styled-components"

const Newsletter = (): React.ReactElement => {
  const [email, setEmail] = React.useState("")
  const [valid, setValid] = React.useState(false)
  React.useEffect(() => setValid(/\S+@\S+\.\S+/.test(email)), [email])
  const submit = () => {
    if (!valid) return
  }
  return (
    <Wrapper
      action="https://gratuitoustype.us1.list-manage.com/subscribe/post?u=125bd434d7080fc2040298425&amp;id=aadbf4f4d5"
      method="post"
      target="_blank"
    >
      <NewsletterInput
        type="email"
        name="EMAIL"
        required={true}
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder="Subscribe for news"
      />
      <HiddenInput
        type="text"
        name="b_125bd434d7080fc2040298425_aadbf4f4d5"
        tabIndex={-1}
        value=""
        onChange={() => {}}
      />
      <ButtonWrapper>
        <SubmitButton
          type="submit"
          disabled={!valid}
          {...{ valid }}
          onClick={() => submit()}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid black;
  background: url("/images/newsletter_background.png");
  background-position: center center;
  overflow: hidden;
  @media only screen and (min-width: 768px) {
    height: 27vw;
  }
`

const HiddenInput = styled.input`
  position: absolute;
  left: -200vw;
`

const NewsletterInput = styled.input`
  width: 80%;
  padding: 15vw 1vw;
  margin: 1em 0;
  border: 2px solid black;
  border-bottom-left-radius: 100% 100%;
  border-bottom-right-radius: 100% 100%;
  border-top-left-radius: 100% 100%;
  border-top-right-radius: 100% 100%;
  outline: 0;
  background: #fff2d9;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  font-size: 6vw;
  text-align: center;
  ::placeholder {
    color: #d6d1cd;
  }
  @media only screen and (min-width: 768px) {
    padding: 5vw 1vw;
    margin: 0;
    font-size: 3.8vw;
  }
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 17vw;
  bottom: 2vw;
  width: 30vw;
  height: 18vw;
  @media only screen and (min-width: 768px) {
    width: 19vw;
    height: 9.5vw;
  }

  transition: transform 0.15s ease-in-out;
  transform: rotateZ(15deg) scale(0.8);
  &:hover {
    transform: rotateZ(5deg) scale(0.85);
  }
  background-image: url("/images/submit-mobile.svg");
  background-size: contain;
  background-repeat: no-repeat;
  @media only screen and (min-width: 768px) {
    background-image: url("/images/submit-desktop.svg");
  }
`

const SubmitButton = styled.input<{ valid: boolean }>`
  position: absolute;
  z-index: 2;
  appearance: none !important;
  padding: 2.9vw 0vw !important;
  border: 0;
  background: transparent;
  outline: 0;
  width: 100%;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  font-size: 8vw;
  text-align: center;
  cursor: pointer;
  color: transparent;
  &:disabled + svg {
    path {
      fill: #ffdc43;
    }
  }
  @media only screen and (min-width: 768px) {
    font-size: 4vw;
  }
`

export default Newsletter
