import React from "react"
import ReactDOMServer from "react-dom/server"
import styled from "styled-components"
import { Link } from "@components/atoms"
import { StickyNoteProps } from "@types"
import Draggable from "react-draggable"

const StickyNote = ({
  posTop,
  posLeft,
  _rawCopy,
  backgroundColor,
  backgroundColor2,
}: StickyNoteProps) => {
  const [active, setActive] = React.useState(true)
  return (
    <Draggable>
      <NotePos {...{ posTop, posLeft }}>
        <Wrapper
          {...{ active }}
          backgroundColor={backgroundColor?.hex ?? "#ffffff"}
          backgroundColor2={backgroundColor2?.hex ?? "#ffffff"}
        >
          <Close onClick={() => setActive(false)} />
          <BlockRenderer>{_rawCopy}</BlockRenderer>
        </Wrapper>
      </NotePos>
    </Draggable>
  )
}

const NotePos = styled.div<{ posTop: number; posLeft: number }>`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
    pointer-events: none;
    position: absolute;
    z-index: 300;
    cursor: default;
    top: ${props => props.posTop}%;
    left: ${props => props.posLeft}%;
    width: 20vw;
  }
`

const Wrapper = styled.aside<{
  backgroundColor: string
  backgroundColor2: string
  active: boolean
}>`
  padding: 13vw 3.5vw 6vw 3.5vw;
  @media only screen and (min-width: 768px) {
    padding: 4vw 1.75vw 3vw 1.75vw;
  }
  text-align: center;

  background: ${props =>
    props.backgroundColor2
      ? `linear-gradient(135deg, ${props.backgroundColor} 0%, ${props.backgroundColor2} 100%)`
      : props.backgroundColor};

  transform-origin: center top;
  transform: ${props =>
    props.active
      ? `none`
      : `
    rotate3d(1, 0.2, 0, 90deg); 
  `};
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? "all" : "none")};
  transition: transform 0.35s ease-in-out, opacity 0.325s ease-in-out;
  will-change: transform, opacity;

  figure {
    margin: 0;
    line-height: 0;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
`

const Close = styled.div`
  display: block;
  position: absolute;
  cursor: pointer;
  &:hover {
    animation: spinZ 0.65s linear infinite;
  }
  width: 4.5vw;
  height: 4.5vw;
  top: 4.5vw;
  left: calc(50% - 2.25vw);
  @media only screen and (min-width: 768px) {
    width: 1.5vw;
    height: 1.5vw;
    top: 1.5vw;
    left: calc(50% - 1vw);
  }
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    height: 2px;
    width: 130%;
    transform-origin: center left;
    transform: rotateZ(45deg);
    background: black;
  }
  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 130%;
    transform-origin: center left;
    transform: rotateZ(-45deg);
    background: black;
  }
`

export const StickyNotesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 51;
  pointer-events: none;
`

import BlockContent from "@sanity/block-content-to-react"

const BlockSerializer = ({ children, node }): JSX.Element => {
  return <StickyNoteCopy>{children}</StickyNoteCopy>
}

const BlockRenderer = ({ children }) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: (props: any) => <BlockSerializer {...props} />,
        },
        marks: {
          link: ({ mark, children }) => {
            const { blank, href } = mark
            const key = Math.random()
              .toString(6)
              .replace(/[^a-z]+/g, "")
            return href.includes("elanaschlenker") ||
              href.substr(0, 1) === "/" ? (
              <Link className="wiggles" to={href}>
                {ReactDOMServer.renderToString(children)
                  .replace("<!-- -->", "")
                  .split(" ")
                  .map((c: string, i: number) => (
                    <span key={`${key}_${i}`}>{c}</span>
                  ))}
              </Link>
            ) : (
              <a className="wiggles" href={href} target="_blank" rel="noopener">
                {ReactDOMServer.renderToString(children)
                  .replace("<!-- -->", "")
                  .split(" ")
                  .map((c: string, i: number) => (
                    <span key={`${key}_${i}`}>{c}</span>
                  ))}
              </a>
            )
          },
        },
      }}
    />
  )
}

const StyledBlockContent = styled(props => <BlockContent {...props} />)`
  > * {
    margin: 1em 0;
    padding: 0;
    &:first-child {
      margin-top: 0;
    }
    &:left-child {
      margin-bottom: 0;
    }
  }
  ul {
    margin-left: 25px;
  }
  li {
    list-style-type: disc;
  }
  img {
    max-width: 100%;
  }
`

const StickyNoteCopy = styled.p`
  text-align: center;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  font-size: 3.6vw;
  @media only screen and (min-width: 768px) {
    font-size: 1.8vw;
  }
  line-height: 1.2em;
  em {
    font-family: "NHaasGroteskDSW01-65Md", sans-serif;
    line-height: inherit;
  }
`

export default StickyNote
