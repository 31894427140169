import React from "react"
import styled from "styled-components"
import { Marquee, GridHelper } from "@components/atoms"

const Footer = (): React.ReactElement => {
  return (
    <>
      {process.env.GATSBY_SANITY_WATCH_MODE && <StyledGridHelper />}
      <Wrapper>
        <StyledMarquee direction="left" speed={15}>
          <FooterLink href="mailto:hello@elanaschlenker.com" target="_blank">
            hello@elanaschlenker.com &nbsp;{" "}
          </FooterLink>
        </StyledMarquee>
      </Wrapper>
    </>
  )
}

const StyledMarquee = styled(props => <Marquee {...props} />)`
  border-top: 2px solid black;
  background: rgb(157, 140, 129);
  @keyframes rainbow {
    0% {
      background-position: 200% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  &:hover {
    animation: rainbow 3s linear infinite;
    background: linear-gradient(
      90deg,
      rgb(56, 185, 255),
      rgb(33, 185, 131),
      rgb(255, 255, 55),
      rgb(255, 160, 98),
      rgb(219, 157, 249),
      rgb(56, 185, 255),
      rgb(33, 185, 131),
      rgb(255, 255, 55),
      rgb(255, 160, 98),
      rgb(219, 157, 249),
      rgb(56, 185, 255)
    );
    background-size: 200% 100%;
  }
  @media only screen and (max-width: 767px) {
    animation: rainbow 3s linear infinite;
    background: linear-gradient(
      90deg,
      rgb(56, 185, 255),
      rgb(33, 185, 131),
      rgb(255, 255, 55),
      rgb(255, 160, 98),
      rgb(219, 157, 249),
      rgb(56, 185, 255),
      rgb(33, 185, 131),
      rgb(255, 255, 55),
      rgb(255, 160, 98),
      rgb(219, 157, 249),
      rgb(56, 185, 255)
    );
    background-size: 200% 100%;
  }
`
{
  /* Blue r56 g185 b255
Green r33 g185 b131
Yellow r255 g 255 b55
Orange: r255 g160 b98
Purple: r219 g157 b249 */
}

const FooterLink = styled.a`
  box-sizing: border-box;
  display: inline-block;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16.5vw;
  @media only screen and (min-width: 768px) {
    font-size: 9.5vw;
  }
  letter-spacing: -0.05em;
  line-height: 1.2em;
  padding-top: 0.025em;
`

const Wrapper = styled.footer`
  position: relative;
`

const StyledGridHelper = styled(props => <GridHelper {...props} />)`
  position: absolute;
  z-index: 9999;
  padding: 10px 20px;
`

export default Footer
