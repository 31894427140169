import React from "react"
import styled from "styled-components"
import { Grid } from "@components/atoms"
import { Link } from "gatsby"
import { TagProps } from "@types"

type TagsProps = {
  tags: Array<TagProps>
  className?: string
  children?: React.ReactElement
}

const Tags = ({
  tags = [],
  children,
  className,
}: TagsProps): React.ReactElement => {
  return (
    <StyledGrid noPadding>
      <Wrapper {...{ className }}>
        <Ul>
          {tags
            .sort((a, b) => a.tag.localeCompare(b.tag))
            .map((t, i) => (
              <Tag
                key={`tag_${i}`}
                color={t.tagSet.color.hex}
                client={t.tagSet.category === "Client"}
              >
                <Link to={`/tags#${t.tag.replace(/ /g, "_")}`}>{t.tag}</Link>
              </Tag>
            ))}
        </Ul>
        {children && <ChildWrapper>{children}</ChildWrapper>}
      </Wrapper>
    </StyledGrid>
  )
}

const StyledGrid = styled(props => <Grid {...props} />)`
  grid-row-gap: 0;
`

const Wrapper = styled.div`
  grid-column-end: span 12;
  grid-column-start: 1;
  padding-bottom: 9.3vw;
  ul {
    padding: 0;
  }
`

const ChildWrapper = styled.ul`
  text-align: center;
  margin: 0;
`

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
`

export const Tag = styled.li<{ color: string; client: boolean }>`
  display: inline-block;
  position: relative;
  list-style-type: none;
  border: 2px solid black;
  border-radius: ${props => (props.client ? 10 : 0)}px;
  background-color: ${props => props.color};
  box-sizing: border-box;
  padding: 0 5px;
  margin: 2px;
  white-space: nowrap;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  font-size: 5.5vw;
  @media only screen and (min-width: 768px) {
    padding: 6px 10px 4px 10px;
    font-size: 2.85rem;
    line-height: 1.1em;
  }
  transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
  transform-origin: center center;
  a {
    transition: color 0.15s ease-in-out;
  }
  &:hover {
    animation: wobble 0.5s linear infinite;
    z-index: 9999;
    a {
      color: ${props => props.color};
    }
    background-color: black;
  }
  &:hover span:nth-of-type(2) {
    animation-direction: reverse;
  }
`

export default Tags
