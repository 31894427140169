import React from "react"
import { useInViewport } from "react-in-viewport"
import { GridItemWrapper, ChildrenWrapper } from "./GridItem"
import { LinkMask, Video } from "@components/atoms"
import { StickyNote } from "@components/molecules"
import { GridItemVideoProps } from "@types"

const GridItemVideo = ({
  link,
  post,
  borderType,
  itemFX,
  layout: { fullBleed, layout },
  children,
  gridItemTypeSwitch,
  stickyNote,
  pageBackgroundColor,
  zIndex,
  className,
}: GridItemVideoProps): React.ReactElement => {
  if (!gridItemTypeSwitch.gridItemVideo) return <></>
  const {
    gridItemVideo: { video, mobileVideo },
  } = gridItemTypeSwitch

  const ref = React.useRef<HTMLDivElement>(null)
  const { inViewport } = useInViewport(ref, { threshold: 0.15 })

  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if (!visible && inViewport) setVisible(true)
  }, [inViewport])

  const { adjustMargin, layer } = itemFX?.effects || {
    adjustMargin: undefined,
    layer: undefined,
  }

  return (
    <GridItemWrapper
      {...{
        ref,
        visible,
        adjustMargin,
        layer,
        fullBleed,
        borderType,
        className,
        zIndex,
        pageBackgroundColor,
      }}
      {...layout}
      {...itemFX}
    >
      {stickyNote?.hasStickyNote && stickyNote?.stickyNote && (
        <StickyNote {...stickyNote.stickyNote} />
      )}
      <LinkMask {...{ link, post }} />
      {mobileVideo && (
        <Video
          {...{ visible }}
          autoPlay
          muted
          loop
          hideIfDesktop
          playsInline
          src={mobileVideo.asset.url}
        />
      )}
      <Video
        {...{ visible }}
        autoPlay
        muted
        loop
        hideIfMobile={!!mobileVideo}
        playsInline
        src={video?.asset.url}
      />
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </GridItemWrapper>
  )
}

export default GridItemVideo
