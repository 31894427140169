import React from "react"
import styled from "styled-components"
import {
  GridItemCaption,
  GridItemImage,
  GridItemVideo,
} from "@components/molecules"
import {
  GridItemProps,
  GridItemCaptionProps,
  GridItemVideoProps,
  GridItemImageProps,
} from "@types"

const GridItem = (props: GridItemProps): React.ReactElement => {
  const defaultLayout = {
    fullBleed: false,
    layout: {
      columns: 12,
      mobileColumns: 6,
      rows: 1,
      columnStart: undefined,
      mobileColumnStart: undefined,
    },
  }
  if (
    props._type === "gridItem" &&
    props.gridItemTypeSwitch?.gridItemType === "caption"
  ) {
    return (
      <GridItemCaption
        {...(props as GridItemCaptionProps)}
        layout={props.layout || defaultLayout}
      />
    )
  }
  if (
    props._type === "gridItem" &&
    props.gridItemTypeSwitch?.gridItemType === "image"
  ) {
    return (
      <GridItemImage
        {...(props as GridItemImageProps)}
        layout={props.layout || defaultLayout}
      />
    )
  }
  if (
    props._type === "gridItem" &&
    props.gridItemTypeSwitch?.gridItemType === "video"
  ) {
    return (
      <GridItemVideo
        {...(props as GridItemVideoProps)}
        layout={props.layout || defaultLayout}
      />
    )
  }
  return <></>
}

interface GridItemWrapperProps {
  fullBleed?: boolean
  hideMobile?: boolean
  mobileColumns?: number
  mobileColumnStart?: number
  columns?: number
  columnStart?: number
  rows?: number
  adjustMargin?: number
  layer?: "normal" | "low" | "high"
  borderType?: "none" | "outline" | "web"
  hasFX: boolean
  visible?: boolean
  zIndex?: number
  effects?: {
    rotation?: number
    staticRotation?: number
    slideX?: number
    slideY?: number
    zoom?: number
  }
  pageBackgroundColor?: string
}

export const GridItemWrapper = styled.div<GridItemWrapperProps>`
  transition: opacity 0.5s ease-in-out;
  will-change: opacity;
  position: relative;
  ${props => props.zIndex && `z-index: ${props.zIndex};`}
  ${props =>
    props.layer &&
    `
    z-index: ${props.layer === "low" ? 1 : props.layer === "normal" ? 2 : 3};
  `}
  grid-column-start: ${props => props.mobileColumnStart || "auto"};
  grid-column-end: span ${props => props.mobileColumns || 6};

  @media only screen and (max-width: 767px) {
    ${props =>
      props.hideMobile &&
      `
      display: none;
    `}
  }
  @media only screen and (min-width: 768px) {
    grid-column-start: ${props => props.columnStart || "auto"};
    grid-column-end: span ${props => props.columns || 12};
    grid-row-end: span ${props => props.rows || 1};
    ${props => props.adjustMargin && `margin-top: ${props.adjustMargin / 2}%;`}
  }

  @media only screen and (min-width: 1024px) {
    ${props => props.adjustMargin && `margin-top: ${props.adjustMargin}%;`}
  }

  & img,
  & video {
    ${props =>
      (props.borderType === "outline" || props.borderType === "web") &&
      `border: 2px solid black;`}
  }

  ${props =>
    props.borderType === "web" &&
    `
      margin-top: 15px;
      &:before {
        position: absolute;
        top: -15px;
        height: 15px;
        width: 100%;
        border: 2px solid black;
        border-bottom: 2px solid black;
        background-color: ${props.pageBackgroundColor ?? "#ececec"};
        content: ' ';
      }
      &:after {
        position: absolute;
        z-index: 1;
        top: -8px;
        left: 7px;
        width: 17px;
        height: 4px;
        content: ' ';
        font-size: 17px;
        margin: 0;
        padding: 0;
        font-family: 'arial', sans-serif;
        letter-spacing: 1px;
        background-image: url('/images/web-dots.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    `}

  ${props =>
    props.fullBleed &&
    `
    border-left: 0px;
    border-right: 0px;
    > *, img, video, div {
      width: 100vw;
    }
    margin-left: -20px;
    grid-column-start: 1 !important;
    grid-column-end: span 6;
    @media only screen and (min-width: 768px) {
      grid-column-end: span 12;
      margin-left: -10vw;
    }
    @media only screen and (min-width: 1600px) {
      margin-left: calc(-1 * (160px + 2vw));
    }
  `}
  
  @media only screen and (min-width: 768px) {
    transform-origin: center center;
    ${props =>
      props.hasFX &&
      `
      transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
      will-change: opacity, transform;
    `}
    ${props =>
      props.effects?.staticRotation &&
      `transform: rotateZ(${props.effects.staticRotation}deg);`}
    &:hover {
      transform: ${props =>
          props.effects?.rotation && `rotateZ(${props.effects.rotation}deg) `}
        ${props =>
          props.effects?.slideX && `translateX(${props.effects.slideX}vw) `}
        ${props =>
          props.effects?.slideY && `translateY(${props.effects.slideY}vh) `}
        ${props =>
          props.effects?.zoom && `scale(${1 + props.effects.zoom * 0.01})`};
    }
  }
`

export const ChildrenWrapper = styled.div``

// todo: move these
export const Description = styled.h3`
  color: rgb(106, 106, 106);
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const ProjectTitle = styled.h3`
  white-space: nowrap;
  display: inline-block;
  padding-right: 15px;
`

export default GridItem
