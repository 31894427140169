/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'
import { HistoryProvider } from '@utils'


export const wrapRootElement = ({ element }) => <HistoryProvider>{element}</HistoryProvider>

