import React from "react"
import styled from "styled-components"

const Grid = ({
  children,
  className,
  noPadding,
  wideMobileRows,
  isFullBleed,
}: GridProps): React.ReactElement => (
  <Wrapper {...{ className, noPadding, wideMobileRows, isFullBleed }}>
    {children}
  </Wrapper>
)

const Wrapper = styled.div<
  Pick<GridProps, "noPadding" | "wideMobileRows" | "isFullBleed">
>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: ${props => (props.noPadding ? 0 : "9vw")} 0;
  ${props =>
    props.isFullBleed &&
    `
    padding-top: 0 !important;
  `}
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 3.3vw;
    grid-row-gap: 3.3vw;
    padding: ${props => (props.noPadding ? 0 : "6.5vw")} 0;
    ${props =>
      props.isFullBleed &&
      `
      padding-top: 0 !important;
    `}
  }
`

type GridProps = {
  noPadding?: boolean
  wideMobileRows?: boolean
  isFullBleed?: boolean
  children: React.ReactNode
  className?: string
}

export default Grid
