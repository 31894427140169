import React from "react"
import ReactDOMServer from "react-dom/server"
import styled from "styled-components"
import { Link } from "@components/atoms"
import BlockContent from "@sanity/block-content-to-react"

const BlockSerializer = ({ children, node }): JSX.Element => {
  switch (node.style) {
    case "h1":
      return <H1>{children}</H1>
    case "h2":
      return <H2>{children}</H2>
    case "h3":
      return <H3>{children}</H3>
    case "c1":
      return <Credits>{children}</Credits>
    default:
      return <Paragraph>{children}</Paragraph>
  }
}

const BlockRenderer = ({ children }) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: (props: any) => <BlockSerializer {...props} />,
        },
        marks: {
          link: ({ mark, children }) => {
            const { blank, href } = mark
            const key = Math.random()
              .toString(6)
              .replace(/[^a-z]+/g, "")
            return href?.includes("elanaschlenker.com") ||
              href?.substr(0, 1) === "/" ? (
              <Link className="wiggles" to={href}>
                {ReactDOMServer.renderToString(children)
                  .replace("<!-- -->", "")
                  .replace("&amp;", "&")
                  .split(" ")
                  .map((c: string, i: number) => (
                    <span key={`${key}_${i}`}>{c}</span>
                  ))}
              </Link>
            ) : (
              <a className="wiggles" href={href} target="_blank" rel="noopener">
                {ReactDOMServer.renderToString(children)
                  .replace("<!-- -->", "")
                  .replace("&amp;", "&")
                  .split(" ")
                  .map((c: string, i: number) => (
                    <span key={`${key}_${i}`}>{c}</span>
                  ))}
              </a>
            )
          },
        },
      }}
    />
  )
}

const StyledBlockContent = styled(props => <BlockContent {...props} />)`
  > p {
    margin-bottom: 0.5em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ul {
    margin-left: 25px;
  }
  li {
    list-style-type: disc;
  }
  img {
    margin: 0.5em 0;
    max-width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const H1 = styled.h1`
  font-family: "kessler-displayregular", serif;
  font-weight: 300;
  font-size: 6.5rem;
  letter-spacing: 0;
  line-height: 1em;
`

const H2 = styled.h2`
  font-family: "kessler-displayregular", serif;
  font-weight: 300;
  font-size: 8vw;
  letter-spacing: 0;
  line-height: 1em;
  margin-bottom: 0.5em;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media only screen and (min-width: 768px) {
    font-size: 4vw;
  }
`

const H3 = styled.h3`
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  letter-spacing: 0;
  line-height: 1.4em;
  margin: 1.15em 0 1em 0;
  font-size: 7vw;
  @media only screen and (min-width: 768px) {
    font-size: 3rem;
  }
`

const Paragraph = styled.p`
  font-size: 7vw;
  line-height: 1.15em;
  a {
    font-family: "NHaasGroteskDSW01-65Md", sans-serif;
    font-size: 6.65vw;
  }
  em {
    font-family: "kessler-displayitalic", serif;
    line-height: inherit;
  }
  @media only screen and (min-width: 768px) {
    font-size: 3rem;
    a {
      font-size: 2.85rem;
    }
  }
`

const Credits = styled.p`
  margin: 0 !important;
  font-size: 5vw;
  line-height: 1.2em;
  a {
    font-family: "NHaasGroteskDSW01-65Md", sans-serif;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    font-size: 4.5vw;
  }
  @media only screen and (min-width: 768px) {
    font-size: 2.3vw;
    a {
      font-size: 2.25vw;
    }
  }
`

export default BlockRenderer
