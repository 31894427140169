import React from "react"
import styled from 'styled-components'


const Margin = ({
  children,
  className,
  noGutter = false,
  id = undefined,
}: MarginProps): React.ReactElement => {
  return (
    <Wrapper className={className} {...{ noGutter, id }} >{children}</Wrapper>
  )
}


const Wrapper = styled.div<Pick<MarginProps, 'noGutter'>>`
  padding-left: ${props => props.noGutter ? 0 : 20}px;
  padding-right: ${props => props.noGutter ? 0 : 20}px;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    padding-left:  ${props => props.noGutter ? 0 : 10}vw;
    padding-right: ${props => props.noGutter ? 0 : 10}vw;
  }
  @media only screen and (min-width: 1600px) {
    padding-left:  ${props => props.noGutter ? 0 : 'calc(160px + 2vw)'};
    padding-right: ${props => props.noGutter ? 0 : 'calc(160px + 2vw)'};
  }
`


export type MarginProps = {
  children?: React.ReactNode
  className?: string
  noGutter?: boolean
  black?: boolean
  id?: string
}


export default Margin
