import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Link } from "@components/atoms"
import { MobileNavigation } from "@components/organisms"
import { MobileNavButton } from "@components/molecules"
import { useHistoryDispatch } from "@utils"

const Header = (): React.ReactElement => {
  const historyDispatch = useHistoryDispatch()

  const { pathname } = useLocation()

  const [navVisible, setNavVisible] = React.useState(false)

  const toggleNav = () => {
    if (navVisible) {
      setNavVisible(false)
      enableBodyScroll(document.querySelector("#body")!)
    } else {
      setNavVisible(true)
      disableBodyScroll(document.querySelector("#body")!)
    }
  }

  React.useEffect(() => {
    historyDispatch({ type: "addUrl", payload: { url: pathname } })
    if (typeof document !== "undefined") {
      document.getElementById("body")?.removeAttribute("class")
      const path = pathname.replace(/\//, "page_")
      document?.getElementById("body")?.classList.add(path)
    }
    setNavVisible(false)
    enableBodyScroll(document.querySelector("#body")!)
  }, [pathname])

  return (
    <>
      <StyledMargin>
        <CornerLink to="/about">
          <img src="/images/icon-about.svg" />
        </CornerLink>
        <Headline>
          <h1>
            <StyledLink to="/">
              <span>Studio</span>
              <span>Elana Schlenker</span>
            </StyledLink>
            <MobileHeader>
              <Link to="/">Studio Elana Schlenker</Link>
            </MobileHeader>
          </h1>
        </Headline>
        <CornerLink to="/tags" $right>
          <img src="/images/icon-tags.svg" />
        </CornerLink>
      </StyledMargin>
      <MobileNavButton onClick={() => toggleNav()} {...{ navVisible }} />
      <MobileNavigation {...{ navVisible }} />
    </>
  )
}

const StyledMargin = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media only screen and (min-width: 768px) {
    height: 20.5vw;
  }
`

const CornerLink = styled(props => <Link {...props} />)<{ $right?: boolean }>`
  position: absolute;
  z-index: 1;
  ${props => (props.$right ? "right: 10px;" : "left: 10px;")}
  top: calc(2vw - 2px);
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
    top: 2.25vw;
    ${props => (props.$right ? "right: 3vw;" : "left: 3vw;")}
  }
  img {
    transform-origin: center center;
    transform: rotateY(0deg);
    height: 4.25vw;
  }
  &:hover {
    img {
      animation: spin 0.75s linear infinite;
    }
  }
`

const Headline = styled.header`
  display: block;
  width: 100%;

  @keyframes wobble {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(5deg);
    }
    75% {
      transform: rotateZ(-5deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }

  h1 {
    font-family: "NHaasGroteskDSW01-65Md", sans-serif;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 9.5vw;
    margin: -0.03vw 0 0 0;
    letter-spacing: -0.02em;
    &:hover span {
      animation: wobble 0.75s linear infinite;
    }
    &:hover span:nth-of-type(2) {
      animation-direction: reverse;
    }
    span {
      margin-left: -0.05em;
      display: block;
      line-height: 0.875em;
      letter-spacing: -0.05em;
      transform-origin: top center;
    }
  }
`

/*
36 from top

I - 12.5 62.5

16 between lines

L - 42 62.5


213 tall
*/

const StyledLink = styled(props => <Link {...props} />)`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`

const MobileHeader = styled.header`
  line-height: 0.85em;
  font-size: 16.5vw;
  padding: 10px 0;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

export default Header
