import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { Footer, Header } from "@components/organisms"
import { SiteSettings } from "@types"
import { palette } from "@theme"

import "../assets/stylesheets/main.scss"

const Layout = ({ className, children }: LayoutProps): React.ReactNode => {
  const { pathname } = useLocation()
  const isFooter = pathname.substr(0, 4) === "/404"

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      sanitySiteSettings {
        ...siteSettings
      }
    }
  `)

  const loaded = true

  const {
    sanitySiteSettings: {
      title,
      description,
      keywords,
      shortcutIcon,
      touchIcon,
      shareImage,
    },
  } = data

  const meta = [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${process.env.GATSBY_SITE_BASE_URL}` },
    { charSet: "UTF-8" },
    {
      name: "viewport",
      content:
        "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0",
    },
    { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
    { name: "apple-mobile-web-app-capable", content: "yes" },

    { property: "og:title", content: title },
    { name: "twitter:title", content: title },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "elanaschlenker" },

    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },

    { name: "keywords", content: keywords },

    {
      property: "og:image",
      content: `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75`,
    },
    { name: "twitter:image", content: shareImage?.asset.url },

    {
      name: "theme-color",
      content: "#b9ff00",
      media: "(prefers-color-scheme: dark)",
    },
    {
      name: "theme-color",
      content: "rgb(255, 243, 217)",
      media: "(prefers-color-scheme: light)",
    },
  ]

  return (
    <>
      <Helmet {...{ meta, title }}>
        <link
          rel="preload"
          as="font"
          href="/fonts/NHaasGrotesk/2ba6fbd5-9c17-4733-af15-f49fbecc5c15.woff"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/Kessler/kesslerdisplayweb-regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link rel="apple-touch-icon" href={touchIcon?.asset.url} />
        <link
          rel="icon"
          type="image/png"
          href={shortcutIcon?.asset.url}
          sizes="32x32"
        />
        <link rel="stylesheet" href="/stylesheets/reset.min.css" />
        <link rel="stylesheet" href="/fonts/Kessler/kessler.css" />
        <link rel="stylesheet" href="/fonts/Kessler-italic/kessler.css" />
        <link rel="stylesheet" href="/fonts/NHaasGrotesk/NHaasGrotesk.css" />
        <meta name="theme-color" content={palette.pinkLady} />
      </Helmet>
      <Top id="top" />
      <Header />
      <LayoutContentWrapper
        className={`${className} ${loaded ? "loaded" : "loading"}`}
        {...{ isFooter }}
      >
        {children}
      </LayoutContentWrapper>
      <Footer />
    </>
  )
}

const LayoutContentWrapper = styled.div<{ isFooter: boolean }>`
  overflow: hidden;
  min-height: 100vh;
  margin-top: calc(40vw + 28px);
  @media only screen and (min-width: 768px) {
    margin-top: 20.5vw;
    min-height: calc(100vh - 11.5vw - 20.5vw);
  }
  .tl-wrapper {
    z-index: 2 !important;
  }
`

const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
`

interface LayoutProps extends SiteSettings {
  subtitle?: string
  preview?: boolean
  hasHero?: boolean
  className?: string
  children: React.ReactNode
}

export default Layout
