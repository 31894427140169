import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { TransitionState } from "gatsby-plugin-transition-link"

type TransitionStatus = "entering" | "entered" | "exiting" | "exited"

const TransitionMask = ({
  transitionStatus,
}: {
  transitionStatus: TransitionStatus
}): React.ReactElement => {
  const { pathname } = useLocation()
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 })
    }, 500)
  }, [pathname])

  return (
    <Mask
      {...{ transitionStatus }}
      className={transitionStatus === "entered" ? "" : "transitioning"}
    />
  )
}

const Mask = styled.div<{
  transitionStatus: TransitionStatus
}>`
  cursor: wait !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  transition: opacity 0.35s 0.1s ease-in-out, visibility 0.01s 0.51s;
  visibility: hidden;
  opacity: 0;
  background-color: #fff3d9;
  &.transitioning {
    pointer-effects: none;
    opacity: 1;
    transition: opacity 0.25s 0.1s ease-in-out, visibility 0.01s 0.01s;
    visibility: visible;
  }
`

const WrappedMask = () => (
  <TransitionState>
    {({ transitionStatus }) => <TransitionMask {...{ transitionStatus }} />}
  </TransitionState>
)

export default WrappedMask
