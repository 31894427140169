import React from "react"
import styled from "styled-components"
import { CheckerBackground } from "@components/atoms"

const imgixPath = (input: string) =>
  input.replace(
    `cdn.sanity.io/images/${process.env.GATSBY_SANITY_PROJECT_ID}/production`,
    "el-images.imgix.net"
  )

type PictureProps = {
  src: string
  mobileSrc?: string
  objectFit?: "contain" | "cover"
  dimensions: {
    width: number
    height: number
    aspectRatio: number
  }
  className?: string
  alt?: string
  columns?: number
  mobileColumns?: number
  blackBackground?: boolean
  lines?: boolean
  visible: boolean
  children?: React.ReactNode
}

const Picture = ({
  objectFit = "contain",
  className = "",
  src,
  mobileSrc,
  dimensions,
  alt,
  columns,
  lines,
  blackBackground,
  visible,
  children,
  ...props
}: PictureProps): React.ReactElement => {
  if (!src) return <></>
  // Set CDN src.
  const mobileCdnSrc = !!mobileSrc ? imgixPath(mobileSrc) : undefined
  const cdnSrc = imgixPath(src)

  // approximate width-of-layout cut by column width
  const desktopCut = (w: number) =>
    !columns ? w : Math.floor(w * (columns / 12))

  const isGif = src.includes(".gif")

  const ref = React.useRef<HTMLImageElement>(null)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (!ref.current) return
    ref.current.onload = () => setLoaded(true)
  }, [ref])

  return (
    <Wrapper>
      <picture style={{ display: "contents" }}>
        {!!children && children}
        {!children && !isGif && (
          <>
            {mobileCdnSrc && (
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${mobileCdnSrc}?fm=webp&q=80&w=840`}
              />
            )}
            {!mobileSrc && (
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${cdnSrc}?fm=webp&q=80&w=840`}
              />
            )}
            <source
              type="image/webp"
              media="(min-width: 1280px)"
              srcSet={`${cdnSrc}?fm=webp&q=80&w=${desktopCut(
                1200
              )}, ${cdnSrc}?fm=webp&q=80&w=${desktopCut(2400)} 2x`}
            />
            <source
              type="image/webp"
              media="(min-width: 1024px)"
              srcSet={`${cdnSrc}?fm=webp&q=80&w=${desktopCut(
                1024
              )}, ${cdnSrc}?fm=webp&q=80&w=${desktopCut(2048)} 2x`}
            />
            <source
              type="image/webp"
              media="(min-width: 768px)"
              srcSet={`${cdnSrc}?fm=webp&q=80&w=${desktopCut(
                768
              )},  ${cdnSrc}?fm=webp&q=80&w=${desktopCut(1536)} 2x`}
            />
          </>
        )}

        <StyledImage
          src={cdnSrc}
          alt={alt}
          {...{ objectFit, lines, blackBackground, ref, visible }}
          {...dimensions}
          className={`loadable ${className} ${loaded && "loaded"}`}
          {...props}
          loading="lazy"
        />
      </picture>
      <CheckerBackground {...{ visible }} />
    </Wrapper>
  )
}

// Styling

const Wrapper = styled.div`
  position: relative;
`

const StyledImage = styled.img<{
  objectFit: "contain" | "cover"
  aspectRatio: number
  lines?: boolean
  blackBackground?: boolean
  visible: boolean
}>`
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: ${props => props.objectFit};
  ${props => props.blackBackground && `background: black;`}
  ${props =>
    props.lines &&
    `
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  `}
  background: url("/images/loading_checks.svg");
  background-size: auto 20px;
  &.loaded {
    background: none;
  }
  transition: opacity 0.35s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
`

export default Picture
