/* important note for Apple touch devices: 
  Limit transforms to items 2000px wide
  (retina doubling == 1000 screen points)
  https://stackoverflow.com/questions/15751012/css-transform-causes-flicker-in-safari-but-only-when-the-browser-is-2000px-w/15759785#15759785
*/


import React from "react"
import styled from 'styled-components'


interface MarqueeProps {
  children: React.ReactNode
  direction?: 'left' | 'right'
  speed?: number
  pauseOnHover?: boolean
  className?: string
}


const Marquee = ({ direction, speed, pauseOnHover, className, children }: MarqueeProps) => {  
  return (
    <Wrapper {...{ direction, pauseOnHover, className }} speed={speed ?? 15}>
      <Contents {...{ children }} />
      <Contents {...{ children }} />
    </Wrapper>
  )
}




const Contents = styled.div`
  display: inline-flex;
  min-width: 100vw;
  justify-content: space-around;
  animation-duration: 15s;
  > * {
    box-sizing: border-box;
    display: block;
    white-space: nowrap;
  }
`

const Wrapper = styled.div<Pick<MarqueeProps, 'direction' | 'speed' | 'pauseOnHover'>>`
  @keyframes scrollToLeft {
    0%   { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  @keyframes scrollToRight {
    0%   { transform: translateX(-100%); }
    100% { transform: translateX(0); }
  }
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  will-change: transform;
  ${Contents} {
    animation: ${props => props.direction === 'left' ? 'scrollToLeft' : 'scrollToRight'} linear infinite;
    animation-duration: ${props => props.speed}s;
  }
  &:hover ${Contents} {
    ${props => props.pauseOnHover && `
      animation-play-state: paused;
    `}
  }
`


export default Marquee