import React from "react"
import styled from 'styled-components'
import { Link, Margin, } from '@components/atoms'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useWindowSize } from '@utils'


export interface RelatedProps {
  title: string
  slug: {
    current: string
  }
  shareImage?: {
    asset: {
      url: string
    }
  }
}

const RelatedPosts = ({nextPost, previousPost}: {nextPost: RelatedProps, previousPost: RelatedProps}) => {
  const [active, setActive] = React.useState(false)
  const { width, height } = useWindowSize()

  const wrapper = React.useRef<HTMLDivElement>(null)
  useScrollPosition(
    ({ currPos }) => setActive( document.body.scrollHeight - currPos.y - height < 0 ), // effect callback
    [height], // dependencies
    wrapper, // position of element
    true, // use window instead of body.getBoundingClientRect
    100, // performance debounce
  )
  if( !previousPost && !nextPost ) return <></>
  return (
    <StyledMargin {...{ active }} >
      <PostsWrapper ref={wrapper} >
        {previousPost && <RelatedPost {...previousPost} {...{ active }} $left />}
        {nextPost && <RelatedPost {...nextPost} {...{ active }}  />}
      </PostsWrapper>
    </StyledMargin>
  )
}


const RelatedPost = ({
  slug,
  shareImage,
  title,
  $left,
  active,
}: RelatedProps & RelatedPostWrapperProps) => (
  <RelatedPostWrapper {...{ $left, active }} className={active ? 'active' : ''} to={`/${slug.current}`} >
    <StyledImg src={shareImage?.asset.url!} alt={title} {...{ active, $left }} className={active ? 'active' : ''} />
    <span className={active ? 'active' : ''} >
      <img src="/images/ses-arrow.svg" />
    </span>
  </RelatedPostWrapper>
)


const StyledMargin = styled(props => <Margin {...props} />)<{ active: boolean }>`
  position: fixed;
  bottom: ${props => props.active ? '27vh' : '-5vh'};
  left: 0;
  width: 100%;
  box-sizing: border-box;
  transition: bottom 0.15s ease-in-out;
  padding-left: 3vw;
  padding-right: 3vw;
  @media only screen and (min-width: 768px) {
    bottom: ${props => props.active ? '12.75vw' : '25px'};
  }
  @media only screen and (min-width: 1250px) {
    bottom: ${props => props.active ? '13vw' : '25px'};
  }
`


const PostsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`


interface RelatedPostWrapperProps {
  $left?: boolean
  active?: boolean
}


const StyledImg = styled.img<RelatedPostWrapperProps>`
  position: absolute;
  z-index: 9999;
  width: 27vw;
  height: auto;
  top: 11vh;
  ${props => props.$left && 'left: 18vw;'}
  ${props => !props.$left && 'right: 18vw;'}
  @media only screen and (min-width: 768px) {
    top: 0;
    width: 12vw;    
    ${props => props.$left && 'left: 0;'}
    ${props => !props.$left && 'right: 0;'}
  }
  border: 2px solid black;

  opacity: 0;
  transform-origin: ${props => props.$left ? 'left' : 'right'} bottom;
  transform: translateY(calc(-100% - 2vw)) rotateZ(${props => props.$left ? -35 : 35}deg);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  &.active {
    opacity: 1;
    transform: translateY(calc(-100% - 2vw)) rotateZ(0deg);
  }
`

const RelatedPostWrapper = styled(props => <Link {...props} />)`
  ${props => props.left ? 'left: 0px;' : 'right: 0px;'}
  display: block;
  position: relative;
  span {
    display: block;
    width: 9vw;
    height: 9vw;
    @media only screen and (min-width: 768px) {
      width: 3vw;
      height: 3vw;
    }
    img {
      position: absolute;
      pointer-events: none;
      z-index: -1;
      ${props => !props.$left && `transform: rotateZ(180deg);`}
      object-fit: contain;
    }
    @keyframes arrow_right_bounce {
      0%   {transform:translateX(0px);  }
      50%  {transform:translateX(-20px);}
      100% {transform:translateX(0px);  }
    }
    @keyframes arrow_left_bounce {
      0%   {transform:translateX(0px);  }
      50%  {transform:translateX(20px);}
      100% {transform:translateX(0px);  }
    }
  }
  &:hover, &.active {
    span {
      animation: ${props => props.$left ? 'arrow_left_bounce' : 'arrow_right_bounce'} 0.5s ease-in-out infinite;      
    }
    ${StyledImg} {
      opacity: 1;
      transform: translateY(calc(-100% - 2vw))rotateZ(0deg);
    }
  }
`



export default RelatedPosts